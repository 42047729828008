/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.MyAccountStatistics {
    &-Wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        @include desktop {
            flex-direction: row;
            column-gap: 20px;
        }
    }

    &-Item {
        background: $neutral-color-8;
        border-radius: 2px;
        padding: 20px;
        width: 100%;
        display: flex;
        height: 139px;

        >svg {
            min-width: 100px;
        }

        &_isCreditLimit {
            @include desktop {
                min-width: 325px;

            }
        }
    }

    &-ItemContent {
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        margin-left: auto;
    }

    &-ItemName {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $font-color-2;
        position: relative;
        width: fit-content;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 3px;
            background-color: $primary-color-1;
            border-radius: 2px;
            position: absolute;
            left: 0;
        }
    }

    &-ItemValue {
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        color: $font-color-1;

        &_isOverduePayments {

            &,
            span {
                color: $status-red-dark;

            }
        }
    }

    &-ItemCurrency {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: $font-color-1;
    }

    &-ItemUsedLimit {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: $font-color-2;

        span {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: $font-color-2;
        }
    }
}
