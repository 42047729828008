/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.MyAccountMyOrders {
    @include desktop {
        margin-top: -10px;
    }
    &-Pagination {
        @include desktop {
            margin: 15px auto 133px;
        }
    }
    &-TableWrapper {
        @include mobile {
            overflow-x: auto;
        }
    }
    &-Table {
        @include mobile {
            table-layout: initial;        
        }
        
        thead th {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: $neutral-color-3;
            vertical-align: bottom;
        }
        
        tbody td {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $font-color-1;
            vertical-align: middle;

            &:nth-of-type(1) {
                line-break: anywhere;
            }
        }
    }
}
