/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.MyAccountOrderInformation {
    &-Wrapper {
        @include mobile {
            margin-bottom: 140px;
        }
    }

    &-Title {
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        color: $font-color-1;
        margin: 30px 0 20px;
        border: 0;
    }

    &-Information {
        column-gap: 20px;
        flex-wrap: wrap;

        >div {
            min-width: 32%;
        }
    }

    &-Column {
        margin-bottom: 20px;

        &_type {

            &_billingAddress,
            &_shippingAddress {
                @include desktop {
                    margin-inline-end: 0;
                }
            }
        }
    }

    &-ColumnTitle {
        background: $neutral-color-6;
        border-radius: 2px;
        padding: 4px;
        margin-bottom: 10px;

        strong {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $font-color-1;
        }

        +div {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $neutral-color-3;

            span {
                color: $neutral-color-3;
            }
        }
    }

    &-PaymentMethod {
        span:not(:last-child) {
            margin-block-end: 2px;
        }
    }
}
