/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

[dir="ltr"] .MyAccountOrder {

    &Information-Wrapper {
        @include desktop {
            margin-bottom: 210px;
        }
    }

    &-Actions {
        @include desktop {
            margin-top: -10px;
        }

        .MyAccountOrderItemsTable-AdditionalInfo {
            width: 100%;     
            
            thead th,
            tbody td {
                @include mobile {
                    min-width: unset;
                }

                &:nth-of-type(1) {
                    @include mobile {
                        max-width: 121px;
                    }
                }

                &:nth-of-type(2) {
                    @include mobile {
                        max-width: 111px;
                    }
                }

                &:nth-of-type(3) {
                    @include mobile {
                        max-width: 115px;
                    }
                }
            }

            thead tr th {
                font-size: 14px;

                @include mobile {
                    vertical-align: bottom;
                }
            }
        }

        .MyAccountOrder-Buttons {
            align-items: center;
            justify-content: flex-end;
            grid-gap: 20px;

            @include mobile {
                flex-direction: column;
                align-items: flex-start;
            }

            @include desktop {
                position: absolute;
                top: -68px;
                right: 0;
            }

            a {
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                text-transform: uppercase;
                color: $secondary-color-3;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                svg {
                    margin-right: 10px;
                }
            }
        }

        table {
            tbody td {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $font-color-1;

                &:nth-of-type(1) {
                    line-break: anywhere;
                }
            }
        }
    }

    &-ProductsTableHeading {
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        color: $font-color-1;
        margin: 30px 0 20px;

        @include mobile {
            margin-top: 40px;
        }
    }

    &-ProductsTable {

        thead th,
        tbody td {
            min-width: unset;
            padding: 16px 10px;

            @include mobile {
                max-width: 100%;
                padding: 4px 0;
                margin: 0 10px;
                min-width: unset;
            }

            &:nth-of-type(1) {
                min-width: 270px;
                max-width: 270px;

                @include mobile {
                    border-top: 2px solid $white;
                    max-width: unset;
                }
            }

            &:nth-of-type(2) {
                min-width: 55px;

                @include mobile {
                    max-width: unset;
                }
            }

            &:nth-of-type(3) {
                max-width: 235px;

                @include mobile {
                    max-width: unset;
                }
            }

            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6),
            &:nth-of-type(7),
            &:nth-of-type(8) {
                text-align: right;
                min-width: 110px;
            }

            &:last-of-type {
                @include mobile {
                    border-bottom: 0;
                }
            }
        }

        thead th {
            background-color: transparent;
            border-right: 2px solid transparent;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            vertical-align: bottom;

            &:last-of-type {
                border-right: 0;
            }
        }

        tbody {
            tr {
                &:nth-of-type(even) {
                    border-radius: 4px;
                }

                &:nth-of-type(odd) {
                    background: $neutral-color-6;
                    border-radius: 4px;
                }

                @include mobile {
                    display: flex;
                    flex-direction: column;
                }
            }

            td {
                border-right: 2px solid $white;
                vertical-align: middle;

                @include mobile {
                    border-right: 0;
                    border-bottom: 2px solid $white;

                }

                &:last-of-type {
                    border-right: 0;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: $font-color-1;
                }

                >div {
                    @include mobile {
                        width: 100%;
                        min-width: unset;
                        max-width: unset;
                        display: flex;
                        justify-content: space-between;
                    }

                    >*:not(.MyAccountOrder-ProductsTableDiscountWrapper) {
                        @include mobile {
                            width: 50%;
                            display: inline-block;
                            text-align: left;
                            min-width: 50%;
                        }
                    }
                }

            }
        }

        .ExpandableContent:last-of-type {
            @include mobile {
                border: 0;
            }
        }

        .ExpandableContent-Button {
            @include mobile {
                padding: 10px;
            }

            .ChevronIcon {
                @include mobile {
                    min-width: var(--toggle-button-size);
                    min-height: var(--toggle-button-size);
                    margin-left: 18px;
                }
            }
        }

        .ExpandableContent-Content {
            @include mobile {
                display: flex;
                flex-direction: column;
            }

            td span {
                font-size: 14px;
                line-height: 20px;

                &:nth-of-type(1) {
                    font-weight: 400;
                    color: $neutral-color-3;
                    padding-right: 12px;

                    span {
                        @include mobile {
                            display: inline-block;
                        }
                    }
                }

                &:nth-of-type(2) {
                    font-weight: 600;
                    color: $font-color-1;
                }
            }
        }
    }

    &-ProductsTableSku {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $neutral-color-3;
        margin-top: 2px;
    }

    &-ProductsTableDiscountWrapper {
        @include mobile {
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
        }
    }

    &-ProductsTableWrapper {
        overflow-x: auto;
    }

    &-ProductsTableOldPrice {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-decoration-line: line-through;
        color: $neutral-color-3;

        @include mobile {
            margin-right: 8px;
        }
    }

    &-ProductsTableDiscount {
        background: $secondary-color-3;
        border-radius: 4px;
        padding: 2px 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        color: $white;
        margin: 4px 0;
        display: inline-block;
    }

    &-ProductsTablePromoPrice {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: $secondary-color-3;
    }

    &-Totals {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;

        @include mobile {
            margin-top: 40px;
        }

        >div {
            max-width: 440px;
            width: 100%;
        }

        div>div:not(.MyAccountOrder-TotalsHeading) {
            color: $neutral-color-3;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0;

            span {
                color: $neutral-color-3;
            }
        }


        &Heading {
            background: $neutral-color-6;
            border-radius: 2px;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $font-color-1;
            padding: 4px;
        }
    }

    &-Reorder {
        font-weight: 700;
        min-height: 36px;
        height: 36px;
        padding-inline: 20px;
        font-size: 14px;
        line-height: 18px;
        margin-right: 0;
    }

    &-ExternalOrder {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        font-weight: 600;
        align-items: center;
        margin-top: 40px;

        span:first-of-type {
            color: $font-color-2;
            font-weight: 400;
            margin-right: 12px;
            text-transform: lowercase;
        }
    }
}
