/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.MyAccountTabList {
    @include desktop {
        min-width: 210px;
        max-width: 210px;
        border: 0;
        background-color: $neutral-color-8;
        border-radius: 4px;
        padding: 4px 0 20px;
        margin-top: 5px;
    }

    &-Separator {
        display: none;
    }

    .MyAccountTabListItem {
        &-Button {
            gap: 16px;
            width: 100%;
        }
    }
}
