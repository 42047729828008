/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

[dir="ltr"] .MyAccountTabListItem {
    position: relative;

    &_isActive,
    &:hover {
        &:not(.MyAccountTabListItem_isLogout):not(.MyAccountTabListItem_isMyAccountMenuPage) {
            .MyAccountTabListItem-Button {
                font-weight: 600;
            }

            &::after {
                content: '';
                display: block;
                width: 4px;
                height: 24px;
                background: $primary-color-1;
                border-radius: 1px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
            }
        }
    }

    &_isLogout {
        padding: 0 14px;
        margin-top: 60px;

        .MyAccountTabListItem-Button {
            text-align: center;
            font-weight: 700;
            justify-content: center;

            &:not(:hover) {
                background-color: #ffffff;
            }
        }
    }

    &_isMyAccountMenuPage {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $font-color-1;
        margin-top: 10px;

        .MyAccountTabListItem-Button {
            padding: 12px 0;

        }

        .ChevronIcon {
            width: 20px;
            height: 20px;
        }
    }

    &-Button {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $font-color-1;

        @include desktop {
            min-width: unset;
            padding: 12px 14px;
        }

        .ChevronIcon {
            @include desktop {
                display: none;
            }
        }
    }
}
