/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.MyAccount {
    @include mobile {
        margin-top: 0;
    }

    &-BackBtn {
        border-bottom: 1px solid $neutral-color-6;
        padding: 0 0 12px 9px;
        margin-bottom: 30px;

        @include mobile {
            display: none;
        }

        a {
            display: inline-flex;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;

            .ChevronIcon {
                top: 2px;
                margin-right: 5px;
            }
        }

        + .MyAccount-Heading {
            display: block;
        }
    }

    &-Wrapper {
        grid-column-gap: 20px;
        padding-inline: 0;
    }

    &-TabContent_activeTab_address {
        .MyAccountChangeDataInfo-ChangeDataInfo {

            @include mobile {
                margin-bottom: 20px;
            }
        }

        @include desktop {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .MyAccount-Heading {
            @include desktop {
                margin-bottom: 0;
            }
        }

        .MyAccountAddressBook {
            @include desktop {
                width: 100%;
                margin-top: 32px;
                margin-bottom: 92px;
            }
        }
    }

    &-Heading {
        font-weight: 600;
        font-size: 36px;
        line-height: 42px;
        color: $font-color-1;

        @include mobile {
            font-size: 28px;
        }

        @include desktop {
            margin-bottom: 40px;
        }
    }

    &-TabContent {
        @include desktop {
            border: 0;
            padding: 0;
        }

        &_activeTab_my-orders {
            .MyAccount-SubHeading {
                display: flex;
                align-items: center;
            }
        }
    }
}
