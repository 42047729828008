/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

[dir="ltr"] .MyAccountDashboard {
    grid-gap: 0;
    margin-bottom: 188px;

    @include mobile {
        margin-bottom: 152px;
    }

    &-BlockTitle {
        border-bottom: 0;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        color: $font-color-1;
        margin-top: 40px;

        @include mobile {
            margin-top: 30px;
            margin-bottom: 20px;
            padding-bottom: 0;
            font-size: 24px;
            line-height: 30px;
        }
    }

    &-Addresses {
        .MyAccountDashboard-BlockTitle {
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
            padding: 0;

            @include mobile {
                margin-bottom: 20px;
                display: block;
            }
        }
    }

    &-DefaultAddress+.MyAccountDashboard-DefaultAddress {
        margin-top: 40px;

        @include mobile {
            margin-top: 20px;

        }

        .KeyValueTable-Heading {
            div {
                display: flex;
                justify-content: space-between;
            }

            a {
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                text-transform: uppercase;
                color: $secondary-color-1;

                &:hover {
                    color: $primary-color-1;
                }
            }
        }
    }

    &-AddressesWrapper {
        @include desktop {
            display: block;
        }
    }
}
