/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.MyAccountOrderStatus {
    &-Status {
        border-radius: 4px;
        padding: 6px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        width: 100px;
        display: inline-block;

        &_status_closed,
        &_status_settled {
            background: $extra-color-9;
            color: $extra-color-10;
        }

        &_status_open {
            background: $extra-color-11;
            color: $extra-color-12;
        }

        &_status_canceled {
            background: $extra-color-13;
            color: $extra-color-14;
        }

        &_status_unsettled {
            background: $extra-color-15;
            color: $extra-color-16;
        }
    }
}