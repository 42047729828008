/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.MyAccountMyWishlist {
    --myaccount-wishlist-action-bar-offset: 85px;

    &-Button {
        @include desktop {
            margin-inline-end: 20px;
            margin-inline-start: 20px;
        }
    }

    .CartIcon {
        fill: $secondary-color-1;
    }

    .MyAccountMyWishlist-Button {
        &:hover {
            .CartIcon {
                fill: $white;
            }
        }
    }

    &-ShareWishlistButton {
        .ShareIcon {
            margin-inline-end: 12px;

            &_isPrimary {
                fill: $white;
            }
        }

        &:not([disabled]):hover .ShareIcon_isPrimary {
            fill: $secondary-color-1;
            transition: ease-out .25s;
        }

        &:not([disabled]) {
            .ShareIcon_isPrimary {
                fill: $secondary-color-1;
            }
        }
    }

    .Button {
        padding-inline: 25px;
    }

    .WishlistItem-Remove {
        z-index: 1;
    }

    &-InfoWrapper {
        margin-bottom: 20px;
    }

    &-StatusInformation {
        display: flex;
        align-items: center;
        background-color: #ffe6e6;
        color: #cc0000;
        padding: 10px 15px;
        border: 1px solid #cc0000;
        border-radius: 4px;
        font-size: 14px;
        justify-content: flex-end;

        svg {
            margin-right: 8px;
            width: 20px;
            height: 20px;

            path {
                stroke: #cc0000;
            }
        }

        div {
            margin-left: 10px;
            color: #cc0000;
        }
    }
}
